
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-article-component] {
  color: var(--theme-color) !important;

  .cursor-none {
    cursor: default;
  }

  .heading {
    color: $light !important;
  }

  h3, ::v-deep p {
    margin-bottom: 0 !important;
  }

  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
}
