
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[navbar-component] {
  height: $header-height;
  z-index: 1;
}

[sidebar-desktop-component] {
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
