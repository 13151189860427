
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-live-event-notice-component] {
  .notice-container {
    background-color: rgba($black, 0.75);
    color: $white !important;
    width: 75%;
    position: relative;

    .notice-close {
      position: absolute;
      right: 1.25rem;
    }

    &:after {
      content: "";
      background-color: $white;
      width: 5px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }

    a {
      white-space: nowrap;
    }
  }

  @include media-breakpoint-down(sm) {
    .notice-container {
      width: 100%;
    }
  }
}
