
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[footer-component] {
  .logo {
    width: 10rem;
    height: auto;
  }
}
