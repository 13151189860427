// Header height
$header-height: 100px;

// Colors
$primary: #9b8db7;
$secondary: #ff9370;
$dark: #2b2b2b;
$light: #f6f6f6;

$light-primary: #f1e9f9;
$light-secondary: #fbe3dc;

$extra-colors: (
  "light-primary": $light-primary,
  "light-secondary": $light-secondary
);

// Fonts
$poppins-font: "MaisonNeue", sans-serif;
$anton-font: "Anton", sans-serif;

$font-family-base: $poppins-font;
$headings-font-family: $anton-font;

$headings-font-weight: 400;
$enable-responsive-font-sizes: true;

$h1-font-size: 6.875rem;
$h2-font-size: 3.75rem;
$h3-font-size: 3.125rem;
$h4-font-size: 1.75rem;
$h5-font-size: 1.25rem;
$display1-size: 12.5rem;

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 916px,
  lg: 1180px,
  xl: 1360px
);

$container-max-widths: (
  sm: 752px,
  md: 880px,
  lg: 1136px,
  xl: 1334px
);

$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;
$btn-font-weight: 700;

@import "~src/stylesheets/fandom_variables";

$colors: map-merge($colors, $extra-colors);
$theme-colors: map-merge($theme-colors, $extra-colors);
