
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[interaction-widget-wrapper] {
  .question-card {
    overflow: hidden;
    border-radius: 12px;
    color: var(--theme-color);

    .wait-message {
      background-color: var(--white);
      color: var(--theme-color);
      z-index: 10;
    }
  }

  @include ie {
    min-width: 100%;
  }

}
