
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$item-size: 1rem;
$line-height: 1.5;
$divider-size: .125rem;
$top-size: calc(#{$item-size} * #{$line-height} / 2);
$bottom-size: calc(100% - #{$top-size});

[list-bullet-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  .text-part {
    font-size: $item-size;
    line-height: $line-height;
    min-height: calc(#{$item-size} * #{$line-height} * 2);
  }

  .divider {
    width: $divider-size;
  }

  .top-part {
    height: calc(#{$top-size} - (#{$item-size} / 2));
  }

  .bottom-part {
    height: calc(#{$bottom-size} - (#{$item-size} / 2));
  }

  .dot {
    height: $item-size;
    width: $item-size;
    background-color: transparent;
    border: $divider-size solid var(--timeline-color);

    &.full {
      background-color: var(--timeline-color);
    }
  }

  .bg-timeline {
    background-color: var(--timeline-color);
  }

  .title {
    font-size: 1.125em;
  }

  .description {
    font-size: .875em;
  }

  .grid {
    display: grid;
    grid-template-columns: auto $item-size 1fr;
    grid-gap: 0 $item-size;
    --timeline-color: #{$primary};
  }
}
