
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[event-interactive-sidebar-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);
  height: auto;
  width: 100%;
  
  --nav-height: 68px;

  &.no-tabs {
    --nav-height: 0px;
  }

  .nav {
    flex-wrap: nowrap;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
      
    &::-webkit-scrollbar { display: none; }

    &-item {
      &--active::after {
        content: "";
        height: 4px;
        background: black;
        position: absolute;
        margin-top: calc(1rem - 5px);
        left: 0;
        right: 0;
      }
    }
  }

  .nav-link {
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.5px;
    color: var(--gray);
    background: $white;
    outline: none;
    cursor: pointer;

    &.active {
      color: $black;
      font-weight: 600;
      outline: none;

      i {
        opacity: 0.75;
      }
    }

    .badge {  
      color: $white;
      background: $player-red-color;
      position: absolute;
      left: -9px;
      top: -7px;
      font-weight: 400;
      font-size: .5rem;
      padding: .4em .4em;
      min-width: 14px;
    }
  }

  .tab-content {
    height: 100%;

    .tab-pane {
      height: 100%;
    }

    ::v-deep {
      [comment] {
        .comment {
          &__with-parent {
            &::before, .parent-comment::before {
              background-color: var(--gray) !important;
            }
          }
        }

        .badge-light {
          background-color: var(--gray) !important;
          color: $white !important;
        }
      }

      [comment-add-component] {
        form {
          width: 100% !important;
        }
      }
    }
  }

  > .container {
    height: calc(100% - var(--nav-height));
  }

  .rounded {
    border-radius: $player-border-radius !important;
  }

  .right-container {
    &__content {
      position: absolute;
      overflow-x: hidden;
      z-index: 8;
      height: 100%;
      left: 0;
    }

    &__detail {
      position: absolute;
      overflow-x: hidden;
      overflow-y: scroll;
      height: 100%;
      left: 0;

      &.visible {
        z-index: 9;
        transform: none !important;
      }
    }
  }

  .info-stripe {
    ::v-deep {
      .container {
        padding: 0;
      }

      [class*="col-"] {
        width: 100% !important;
        max-width: 100%;
        flex: 0 0 100%;
      }

      [class*="row-cols-"] {
        &>* {
          width: 100% !important;
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }

  ::v-deep {
    [player-quiz-component] {
      [simple-form-component] {
        .container .row {
          padding-top: 0 !important;
        }

        [class*="col-"] {
          width: 100% !important;
          max-width: 100%;
          flex: 0 0 100%;
        }

        [class*="row-cols-"] {
          &>* {
            width: 100% !important;
            max-width: 100%;
            flex: 0 0 100%;
          }
        }
      }
    }
  }
}
