
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  video {
    object-fit: cover;
  }

  &:not([href]):hover {
    color: var(--theme-color);
  }

  .tile__content {
    opacity: 0;
    transform: translateY(-100%);
    transition: all .3s ease-out;
  }

  &:hover {
    .tile__content {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .tile__icon {
    width: 4rem;
  }

  .tile__lock-icon {
    right: 0;
    width: 7rem;
    bottom: 2rem;
    background-color: $primary;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
  }

  h3, ::v-deep p {
    margin-bottom: 0 !important;
  }
}
