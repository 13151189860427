
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

@include media-breakpoint-down(md) {
  /* Forced no vertical padding on mobile */
  [two-col-text-component] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

/* IE11 hack */
@include ie {
  div > .row {
    max-height: 1080px;
  }
  .text-column {
    min-height: inherit;
    max-height: 1080px;
  }
}
