
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[navbar-secondary-component] {
  .dark-theme {
    background-color: $dark;

    .nav-link {
      color: rgba($white, .5) !important;
    } 
  }

  &.sticky {
    position: sticky;
    z-index: 1;
  }
}
