
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  


.cursor-default {
  cursor: default !important;
}

.modal-photo {
  width: 5rem;
  height: 5rem;
}
