
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-infinite-text-component] {

  @keyframes infiniteScroll {
    from { transform: translateX(0) }
    to { transform: translateX(-50%) }
  }

  .scrolling-items {
    animation-name: infiniteScroll;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    .item {
      &-icon, &-text {
        font-size: $display1-size;
      }
    }
  }
}
