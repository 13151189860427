
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[player-banners-component] {

  --banner-width: 250px;
  --banner-height: 72px;

  @include media-breakpoint-down(lg) {
    --banner-width: 200px;
    --banner-height: 48px;
  }

  .banner {
    max-width: var(--banner-width);
    height: var(--banner-height);
    font-size: .75rem;
    line-height: 15px;
    letter-spacing: -0.5px;
    cursor: pointer;
    background-color: $white;
    border-radius: $player-border-radius-sm;
    position: relative;

    @include media-breakpoint-down(lg) {
      font-size: .625rem;
      line-height: 11px;
    }

    &__image {
      padding: 2px;
      border-bottom-left-radius: $player-border-radius-sm;
      border-top-left-radius: $player-border-radius-sm;

      .image {
        max-width: calc(var(--banner-height) - 4px);
        width: calc(var(--banner-height) - 4px);
        background: #fff;
        height: 100%;

        div {
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: $player-border-radius-sm;
        }
      }
    }

    &__text {
      flex: 1;
      color: $dark;
      font-weight: 600;
      display: flex;
      align-items: center;

      span {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__more-info {
      flex: 2;
      max-width: var(--banner-height);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #F2F2F3;
      color: $dark;
      padding: 0 .5rem;
      border-radius: $player-border-radius-sm;

      ::v-deep .line-through {
        text-decoration: line-through;
      }
    }

    &.small {
      width: var(--banner-height);
      max-width: var(--banner-height);

      .banner {
        &__content {
          flex-direction: column;
        }

        &__text {
          display: none;
        }

        &__image {
          flex: 2;
          border-top-right-radius: $player-border-radius-sm;
          overflow: hidden;
        }

        &__more-info {
          flex-direction: row;
          justify-content: center;
          padding: 0;
          background: $white;           

          ::v-deep span {
            &:not(.main-info) {
              display: none !important;
            }
          }
        }
      }
    }

    &.preflashsale {
      .countdown {
        border-bottom-right-radius: $player-border-radius-sm;
        border-top-right-radius: $player-border-radius-sm;
      }
    }

    &.flashsale {
      border: 1px solid $player-red-color;

      ::v-deep .main-info {
        color: $player-red-color;
      }
    }

    &__countdown {
      background: $player-red-color;
      color: $white;
      position: absolute;
      border: 1px solid $player-red-color;
      border-bottom-left-radius: $player-border-radius-sm;
      border-bottom-right-radius: $player-border-radius-sm;
      font-weight: 600;
      font-size: .875rem;
      line-height: 14px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 2px 4px;
      bottom: -20px;
      left: -1px;
      height: 29px;
      width: var(--banner-height);

      @include media-breakpoint-down(lg) {
        font-size: .5rem;
        line-height: .5rem;
        bottom: -15px;
        height: 21px;
      }
    }
  }
}
