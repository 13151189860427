
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[list-keypoints-component] {
  .keypoint-image {
    width: 10rem;
  }

  .link-primary .fa-arrow-right {
    color: var(--primary);
  }
}
