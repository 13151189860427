
    @import "tenants/tenants-hfarm/hfarm_forhuman/javascript/src/stylesheets/hfarm_forhuman_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-accordion-component] {
  background-position: top;
  background-size: contain;

  .icon-container {
    background-color: var(--theme-background);
    width: 2rem;
    height: 2rem;
  }

  a.accordion.collapsed .icon-container::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f067";
  }

  a.accordion .icon-container::before {
    font-family: "Font Awesome 5 Pro";
    content: "\f068";
    color: var(--primary);
  }

  .separator {
    height: 1px;
  }

}
