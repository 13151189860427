html {
  font-size: 16px;
}

@for $i from 1 through 4 {
  .display-#{$i} { 
    font-family: $anton-font;
    text-transform: uppercase;
  }
}

@for $i from 1 through 6 {
  h#{$i}, .h#{$i} { 
    text-transform: uppercase;
  }
}

@include media-breakpoint-up(xl) {
  .w-limiter {
    max-width: 2560px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-height: 1080px) {
  .mh-100vh,  
  .mh-100vh-header {
    height: 1440px !important;
    min-height: auto !important;
  }
}

.btn {
  text-transform: uppercase;
}

.link {
  color: inherit !important;
  text-decoration: none !important;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    transform: scaleX(0);
    transform-origin: left;
    background-color: currentColor;
    transition: transform .4s cubic-bezier(.33,.52,.05,.96);
  }

  &:hover:after, &.active:after {
    transform: scaleX(1);
  }
}